<template>
  <v-dialog v-model="show" max-width="650" :fullscreen="$vuetify.breakpoint.mobile">
    <v-card v-if="currentItem">
      <v-row no-gutters>
        <v-card-title>{{ isEditing ? 'Edit currency code' : 'Add new currency code' }}</v-card-title>
        <v-btn depressed v-if="$vuetify.breakpoint.mobile" class="ml-auto mr-4 mt-4" icon @click="show = false">
          <v-icon dark>mdi-close-thick</v-icon>
        </v-btn>
      </v-row>
      <v-card-text>
        <v-form v-model="isValid" ref="editItemRef">
          <v-row>
            <v-col cols="12">
              <v-text-field :rules="[rules.required]" v-model="currentItem.name" dense label="Name"></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-block d-md-flex">
        <v-btn depressed :block="$vuetify.breakpoint.mobile" @click="show = false" class="my-4">Cancel</v-btn>
        <v-btn
          depressed
          :block="$vuetify.breakpoint.mobile"
          @click="saveCurrent"
          color="success"
          class="ml-0 ml-md-4 my-4"
          :loading="isSaving"
          :disabled="!isValid"
        >
          <v-icon left>mdi-check</v-icon>
          {{ isEditing ? 'Update code' : 'Add code' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';
import apiService from '@/services/apiService.js';

export default {
  name: 'CreateEditCurrencyCode',
  data: () => ({
    show: false,
    currentItem: {},
    isSaving: false,
    isValid: null,
    rules: {
      required: (value) => !!value || 'Field cannot be empty.',
    },
  }),

  computed: {
    isEditing() {
      return this.currentItem?.id ? true : false;
    },
  },
  methods: {
    addItem() {
      this.show = true;
      this.currentItem = {
        name: null,
      };
    },
    editItem(currencyCode) {
      this.show = true;
      this.currentItem = _.cloneDeep(currencyCode);
    },
    async saveCurrent() {
      this.isSaving = true;
      await apiService
        .updateCurrencyCode(this.currentItem)
        .then(() => {
          this.show = false;
          this.$emit('item-updated');
        })
        .finally(() => {
          this.isSaving = false;
        });
    },
  },
};
</script>
