<template>
  <div v-if="!isLoading">
    <v-row>
      <v-col cols="12" sm="auto">
        <h1 class="text-left">{{ currentItem.name }}</h1>
      </v-col>
      <v-col cols="12" sm="auto">
        <v-btn :block="$vuetify.breakpoint.mobile" depressed class="align-self-center mt-2 ml-md-4 mb-3 mb-md-0" @click="editItem">
          <v-icon left>mdi-pencil-outline</v-icon>
          Edit
        </v-btn>
        <v-btn :block="$vuetify.breakpoint.mobile" color="error" outlined depressed class="align-self-center mt-2 ml-md-4 mb-3 mb-md-0" @click="deleteItem">
          <v-icon left>mdi-delete</v-icon>
          Delete
        </v-btn>
      </v-col>
    </v-row>

    <v-simple-table dense class="my-4">
      <tbody>
        <tr>
          <td class="font-weight-bold">Id:</td>
          <td>{{ currentItem.id }}</td>
        </tr>
        <tr>
          <td class="font-weight-bold">Name:</td>
          <td>{{ currentItem.name }}</td>
        </tr>
        <tr>
          <td class="font-weight-bold">Last updated:</td>
          <td><last-updated-field :item="currentItem" /></td>
        </tr>
      </tbody>
    </v-simple-table>
    <create-edit-currency-code ref="CreateEditCodeRef" @item-updated="bubbleEmit" />
  </div>
</template>

<script>
import apiService from '@/services/apiService.js';
import CreateEditCurrencyCode from '../Dialogs/CreateEditCurrencyCode.vue';

export default {
  name: 'CurrencyCodeDetails',
  components: { CreateEditCurrencyCode },
  data: () => ({
    currentItem: null,
    isLoading: false,
  }),
  methods: {
    async getItem() {
      this.isLoading = true;
      this.currentItem = await apiService.getCurrencyCodeById(this.$route.params.Id);
      this.isLoading = false;
    },
    editItem() {
      this.$refs.CreateEditCodeRef.editItem(this.currentItem);
    },
    bubbleEmit() {
      this.getItem().then(() => {
        this.$emit('item-updated');
      });
    },
    async deleteItem() {
      if (confirm('Are you sure you want to delete this currency code? This cannot be undone.')) {
        await apiService.deleteCurrencyCode(this.currentItem.id);
        this.$router.back();
      }
    },
  },
  created() {
    this.getItem();
  },
};
</script>